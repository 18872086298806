import Schedule from './components/Schedule';
import Location from './components/Location';
import Social from './components/Social';
import logo from './assets/logo_official.png';
import logoB from './assets/bd.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App--header">
        <img src={logo} className="App-logo" alt="BD" /> 
        <img src={logoB} alt="Black Dragon Akademija" className="App--logoB" />
        <Social />
      </header>
      <div className="App--page">
        <h3>USKORO - raspored za sezonu 2024./2025.</h3>
        <h3>Raspored treninga od 21.5.2024.</h3>
        <Schedule />
        <h3>Lokacija</h3>
        <Location />
      </div>
    </div>
  );
}

export default App;
