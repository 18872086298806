import fb from '../assets/fb.png';
import ig from '../assets/ig.png';

function Social() {
    return (
        <div className="Social">
            <a href="https://www.facebook.com/BlackDragonAcademy" target="_blank">
                <img src={fb} alt="Facebook" />
            </a>
            <a href="https://www.instagram.com/alliance_croatia/" target="_blank">
                <img src={ig} alt="Instagram" />
            </a>
        </div>
    );
}

export default Social;
