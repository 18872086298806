function Schedule() {
    return (
      <table className="Schedule">
        <thead>
          <tr>
              <th>
                  Ponedjeljak
              </th>
              <th>
                  Utorak
              </th>
              <th>
                  Srijeda
              </th>
              <th>
                  Četvrtak
              </th>
              <th>
                  Petak
              </th>
              <th>
                  Subota
              </th>
          </tr>
        </thead>
        <tbody>
          <tr>
              {/* PONEDJELJAK */}
              <td>
                  <div className="Schedule-time">
                      <strong>09:00</strong>
                  </div>
                  <div className="Schedule-class">
                      NO-GI (Jiu Jitsu/Wrestling)
                  </div>
                  <div className="Schedule-duration">
                      90 min.
                  </div>
              </td>
              {/* UTORAK */}
              <td>
                  <div className="Schedule-time">
                      <strong>9:00</strong>
                  </div>
                  <div className="Schedule-class">
                      TAJLANDSKI BOKS
                  </div>
                  <div className="Schedule-duration">
                      90 min.
                  </div>
              </td>
              {/* SRIJEDA */}
              <td>
                  <div className="Schedule-time">
                      <strong>09:00</strong>
                  </div>
                  <div className="Schedule-class">
                      NO-GI (Jiu Jitsu/Wrestling)
                  </div>
                  <div className="Schedule-duration">
                      90 min.
                  </div>
              </td>
              {/* ČETVRTAK */}
              <td>
                  <div className="Schedule-time">
                      <strong>9:00</strong>
                  </div>
                  <div className="Schedule-class">
                      TAJLANDSKI BOKS
                  </div>
                  <div className="Schedule-duration">
                      90 min.
                  </div>
              </td>
              {/* PETAK */}
              <td>
                  <div className="Schedule-time">
                      <strong>9:00</strong>
                  </div>
                  <div className="Schedule-class">
                      TAJLANDSKI BOKS
                  </div>
                  <div className="Schedule-duration">
                      90 min.
                  </div>
              </td>
              {/* SUBOTA */}
              <td>
                  &nbsp;
              </td>
          </tr>
          <tr>
              {/* PONEDJELJAK */}
              <td>
                  <div className="Schedule-time">
                      <strong>12:00</strong>
                  </div>
                  <div className="Schedule-class">
                    Jiu-Jitsu<br />SPARRING (All belts)
                    <br />
                    <span style={{ fontSize: "0.8em" }}>
                        <i>* uz prethodni dogovor</i>
                    </span>
                  </div>
                  <div className="Schedule-duration">
                      60 min.
                  </div>
              </td>
              {/* UTORAK */}
              <td>
                  {/* <div className="Schedule-time">
                      <strong>18:00</strong>
                  </div>
                  <div className="Schedule-class">
                      NO-GI
                  </div>
                  <div className="Schedule-duration">
                      60 min.
                  </div> */}
              </td>
              {/* SRIJEDA */}
              <td>
                  <div className="Schedule-time">
                      <strong>12:00</strong>
                  </div>
                  <div className="Schedule-class">
                    Jiu-Jitsu<br />SPARRING (All belts)
                    <br />
                    <span style={{ fontSize: "0.8em" }}>
                        <i>* uz prethodni dogovor</i>
                    </span>
                  </div>
                  <div className="Schedule-duration">
                      60 min.
                  </div>
              </td>
              {/* ČETVRTAK */}
              <td>
                  {/* <div className="Schedule-time">
                      <strong>18:00</strong>
                  </div>
                  <div className="Schedule-class">
                      NO-GI
                  </div>
                  <div className="Schedule-duration">
                      60 min.
                  </div> */}
              </td>
              {/* PETAK */}
              <td>
                  <div className="Schedule-time">
                      <strong>12:00</strong>
                  </div>
                  <div className="Schedule-class">
                    Jiu-Jitsu<br />SPARRING (All belts)
                    <br />
                    <span style={{ fontSize: "0.8em" }}>
                        <i>* uz prethodni dogovor</i>
                    </span>
                  </div>
                  <div className="Schedule-duration">
                      60 min.
                  </div>
              </td>
              {/* SUBOTA */}
              <td>
                  <div className="Schedule-time">
                      <strong>12:00</strong>
                  </div>
                  <div className="Schedule-class">
                      Jiu-Jitsu<br />KIDS NO-GI
                  </div>
                  <div className="Schedule-duration">
                      60 min.
                  </div>
              </td>
          </tr>
          <tr>
              {/* PONEDJELJAK */}
              <td>
                  <div className="Schedule-time">
                      <strong>17:00</strong>
                  </div>
                  <div className="Schedule-class">
                      TAJLANDSKI BOKS
                  </div>
                  <div className="Schedule-duration">
                      90 min.
                  </div>
              </td>
              {/* UTORAK */}
              <td>
                  <div className="Schedule-time">
                      <strong>17:00</strong>
                  </div>
                  <div className="Schedule-class">
                    Jiu-Jitsu<br />NO-GI (All belts)
                  </div>
                  <div className="Schedule-duration">
                      60 min.
                  </div>
              </td>
              {/* SRIJEDA */}
              <td>
                  <div className="Schedule-time">
                      <strong>17:00</strong>
                  </div>
                  <div className="Schedule-class">
                      TAJLANDSKI BOKS
                  </div>
                  <div className="Schedule-duration">
                      90 min.
                  </div>
              </td>
              {/* ČETVRTAK */}
              <td>
                <div className="Schedule-time">
                      <strong>17:00</strong>
                  </div>
                  <div className="Schedule-class">
                    Jiu-Jitsu<br />NO-GI (All belts)
                  </div>
                  <div className="Schedule-duration">
                      60 min.
                  </div>
              </td>
              {/* PETAK */}
              <td>
                  <div className="Schedule-time">
                      <strong>17:00</strong>
                  </div>
                  <div className="Schedule-class">
                      TAJLANDSKI BOKS
                  </div>
                  <div className="Schedule-duration">
                      90 min.
                  </div>
              </td>
              {/* SUBOTA */}
              <td>
                  &nbsp;
              </td>
          </tr>
          <tr>
              {/* PONEDJELJAK */}
              <td>
                  <div className="Schedule-time">
                      <strong>18:00</strong>
                  </div>
                  <div className="Schedule-class">
                    Jiu-Jitsu<br />FUNDAMENTALS
                  </div>
                  <div className="Schedule-duration">
                      60 min.
                  </div>
              </td>
              {/* UTORAK */}
              <td>
                  <div className="Schedule-time">
                      <strong>18:00</strong>
                  </div>
                  <div className="Schedule-class">
                      Jiu-Jitsu<br />INTERMEDIATE GI (All belts)
                  </div>
                  <div className="Schedule-duration">
                      75 min.
                  </div>
              </td>
              {/* SRIJEDA */}
              <td>
                  <div className="Schedule-time">
                      <strong>18:00</strong>
                  </div>
                  <div className="Schedule-class">
                    Jiu-Jitsu<br />FUNDAMENTALS
                  </div>
                  <div className="Schedule-duration">
                      60 min.
                  </div>
              </td>
              {/* ČETVRTAK */}
              <td>
                  <div className="Schedule-time">
                      <strong>18:00</strong>
                  </div>
                  <div className="Schedule-class">
                      Jiu-Jitsu<br />INTERMEDIATE GI (All belts)
                  </div>
                  <div className="Schedule-duration">
                      75 min.
                  </div>
              </td>
              {/* PETAK */}
              <td>
                <div className="Schedule-time">
                      <strong>18:00</strong>
                  </div>
                  <div className="Schedule-class">
                    Jiu-Jitsu<br />OPEN MAT
                  </div>
                  <div className="Schedule-duration">
                      90 min.
                  </div>
              </td>
              {/* SUBOTA */}
              <td>
                <div className="Schedule-time">
                    <strong>18:00</strong>
                </div>
                <div className="Schedule-class">
                    Jiu-Jitsu<br />OPEN MAT (GI/NO-GI)
                </div>
                <div className="Schedule-duration">
                    90 min.
                </div></td>
          </tr>
          <tr>
            {/* PONEDJELJAK */}
            <td>
                <div className="Schedule-time">
                      <strong>19:00</strong>
                  </div>
                  <div className="Schedule-class">
                      Jiu-Jitsu<br />ADVANCED
                  </div>
                  <div className="Schedule-duration">
                      75 min.
                  </div>
              </td>
              {/* UTORAK */}
              <td>
                  <div className="Schedule-time">
                      <strong>19:30</strong>
                  </div>
                  <div className="Schedule-class">
                      Jiu-Jitsu<br />
                      KIDS 7-11 YEARS
                  </div>
                  <div className="Schedule-duration">
                      60 min.
                  </div>
              </td>
              {/* SRIJEDA */}
              <td>
                <div className="Schedule-time">
                      <strong>19:00</strong>
                  </div>
                  <div className="Schedule-class">
                    Jiu-Jitsu<br />ADVANCED
                  </div>
                  <div className="Schedule-duration">
                      75 min.
                  </div>
              </td>
              {/* ČETVRTAK */}
              <td>
                  <div className="Schedule-time">
                      <strong>19:30</strong>
                  </div>
                  <div className="Schedule-class">
                      Jiu-Jitsu<br />
                      KIDS 7-11 YEARS
                  </div>
                  <div className="Schedule-duration">
                      60 min.
                  </div>
              </td>
              {/* PETAK */}
              <td>
              </td>
              {/* SUBOTA */}
              <td>
                  &nbsp;
              </td>
          </tr>
          <tr>
            {/* PONEDJELJAK */}
            <td>
                <div className="Schedule-time">
                      <strong>20:30</strong>
                  </div>
                  <div className="Schedule-class">
                    Jiu-Jitsu<br />FUNDAMENTALS
                  </div>
                  <div className="Schedule-duration">
                      60 min.
                  </div>
              </td>
              {/* UTORAK */}
              <td>
                  <div className="Schedule-time">
                      <strong>20:30</strong>
                  </div>
                  <div className="Schedule-class">
                      Jiu-Jitsu <br />
                      KIDS 12-15 YEARS
                  </div>
                  <div className="Schedule-duration">
                      60 min.
                  </div>
              </td>
              {/* SRIJEDA */}
              <td>
                <div className="Schedule-time">
                      <strong>20:30</strong>
                  </div>
                  <div className="Schedule-class">
                    Jiu-Jitsu<br />FUNDAMENTALS
                  </div>
                  <div className="Schedule-duration">
                      60 min.
                  </div>
              </td>
              {/* ČETVRTAK */}
              <td>
                  <div className="Schedule-time">
                      <strong>20:30</strong>
                  </div>
                  <div className="Schedule-class">
                      Jiu-Jitsu <br />
                      KIDS 12-15 YEARS
                  </div>
                  <div className="Schedule-duration">
                      60 min.
                  </div>
              </td>
              {/* PETAK */}
              <td>
              </td>
              {/* SUBOTA */}
              <td>
                &nbsp;
              </td>
          </tr>
        </tbody>
      </table>
    );
  }
  
  export default Schedule;
  