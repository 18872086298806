import { MapContainer, TileLayer, Popup, Marker } from 'react-leaflet';

function Location() {
    const position = [45.7819, 15.94575]
    return (
        <div className="Location">
            <p>
                Hrvatski akademski Ju-Jitsu klub "Mladost-Alliance BD"
            </p>
            <p>
                Jarunska ulica 3, Zagreb (Sportski park Mladost)
            </p>
            <p>
                Informacije o treninzima: <a className="Location--phone" href="tel:098483597">+385 98 483 597</a>
            </p>
            &nbsp;
            <div id="map" style={{ height: '500px' }}>
                <MapContainer center={position} zoom={16} scrollWheelZoom={false}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker position={position}>
                    <Popup>
                        Hrvatski akademski Ju-Jitsu klub<br />"Mladost-Alliance BD"
                    </Popup>
                    </Marker>
                </MapContainer>
            </div>
        </div>
    );
}

export default Location;
